<template>
  <div>
    <search-funeral-home @submit="submitSearch" />

    <a-table
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
      @change="fetchData"
    >
      <template slot="images" slot-scope="imgs, record">
        <!--        <span v-if="imgs && imgs.length > 0" class="custom-blue" @click="showImg(record)">{{ imgs[0].response.filename }}</span>-->
        <img v-if="imgs && imgs.length > 0" :src="imgs[0].url" style="height: 50px;width: 50px" @click="showImg(record)">
      </template>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 预览图片 -->
    <preview-image
      :visible.sync="previewVisible"
      :images.sync="previewImages"
    />

  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import SearchFuneralHome from '@/views/tenants/funeral_homes/Search'
import PreviewImage from '@/components/PreviewImage'
import { findTenantFuneralHomeList } from '@/api/tenant'

export default {
  name: 'FuneralList',
  components: {
    Pagination,
    PreviewImage,
    // eslint-disable-next-line vue/no-unused-components
    SearchFuneralHome
  },
  data() {
    return {
      data: [],
      loading: true,
      previewVisible: false,
      previewImages: [],
      pagination: {
        total_count: 0
      },
      query: {}
    }
  },
  created() {
    this.fetchData()
    this.$EventBus.$on('showImg', val => {
      this.previewVisible = true
      this.previewImages = val.images
    })
  },
  computed: {
    tenantId() {
      return parseInt(this.$route.params.id)
    },
    columns() {
      return [
        {
          title: '殡仪馆名称',
          dataIndex: 'name',
          width: 160
        },
        {
          title: '地址',
          dataIndex: 'address',
          width: 100
        },
        {
          title: '厅房数量',
          dataIndex: 'hall_count',
          width: 100
        },
        {
          title: '介绍',
          width: 120,
          dataIndex: 'remark'
        },
        {
          title: '状态',
          width: 90,
          dataIndex: 'effective_display'
        }
      ]
    }
  },
  methods: {
    showImg(record) {
      this.previewVisible = true
      this.previewImages = record.images
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findTenantFuneralHomeList(this.tenantId, this.query).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    }
  }
}
</script>
